// project imports
import MainLayout from 'layout/MainLayout';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import SocialUsersView from '../views/pages/socialUsers/view';

const Shop = {
  path: '/',
  element: (
    <ProtectedRoute>
      <MainLayout />
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'social/users',
      element: (
        <ProtectedRoute permission={'ADMIN'}>
          <SocialUsersView />
        </ProtectedRoute>
      )
    }
  ]
};

export default Shop;
