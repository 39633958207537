import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UITypography } from '../../utilities/Typography';
import { Box, Pagination } from '@mui/material';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import ReviewStars from '../../utilities/Stars';
import moment from 'moment';

const ModalReviews = ({ modalOpen, handleModalClose }) => {
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  let { onListDoctorReviews } = useAccounts();

  const listReviews = () => {
    onListDoctorReviews({
      page,
      limit: 20
    }).then((res) => {
      setReviews(res?.rows);
      setTotalPages(res?.count);
      setLoading(false);
    });
  };

  useEffect(() => {
    listReviews();
  }, [page]);

  const handlePageChange = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    if (modalOpen) {
      listReviews();
    }
  }, [modalOpen]);

  const handleModalCloseInternal = () => {
    setPage(1);
    setReviews([]);
    setLoading(true);
    handleModalClose();
  };


  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <UiModalInternal onClose={handleModalCloseInternal} containerSx={
        { maxWidth: '50%' }
      } isLoading={loading}>
        <UIModalHeader
          sx={{
            bgcolor: '#FFF'
          }}
        >
          <UITypography
            variant={'Oxygen700'}
            title={`Lista recensioni`}
            size={'15px'}
            sxCustom={{
              textAlign: 'center',
              color: '#101828'
            }}
          />
        </UIModalHeader>
        <UIModalBody
          buttonsSpace={false}
          sx={{
            bgcolor: 'transparent',
            p: '10px 20px',
            height: '90%',
          }}
        >
          <Box sx={{
            overflowY: 'auto',
            height: '90%',
          }}>
            {reviews.map((review, index) => (
              <Box key={index} sx={{
                background: 'rgba(236,236,236,1)',
                paddingX: '1.5rem',
                paddingY: '1.5rem',
                borderRadius: '26px',
                marginBottom: '1rem'
              }}>
                <UITypography sxCustom={{
                  color:'black'
                }}>
                  {`${review?.appointment?.patient?.name} ${review?.appointment?.patient?.surname?.slice(0, 1)}.`}
                </UITypography>
                <Box sx={{
                  marginY: '0.3rem'
                }}>
                  <ReviewStars rating={review?.rating} />
                </Box>

                <UITypography sxCustom={{
                  fontWeight: 300,
                  color: 'black',
                  fontSize: '14px',
                  marginBottom: '0.5rem'
                }}>
                  {review?.review}
                </UITypography>

                <UITypography sxCustom={{
                  fontWeight: 300,
                  color: 'black',
                  fontSize: '10px',
                  textAlign: 'right',
                }}>
                  {moment(Number(review?.createdAt)).format('DD/MM/YY HH:mm')}
                </UITypography>


              </Box>
            ))}
          </Box>

          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} mt={2}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} color={'primary'} />
          </Box>

        </UIModalBody>
      </UiModalInternal>
    </UIModal>
  );
};

export default ModalReviews;
