/*eslint-disable*/
import { Color } from '@tiptap/extension-color';
import ListItem from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';
import { BubbleMenu, EditorContent, FloatingMenu, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { UIButton } from './Button';
import {
  IconArrowBackUp,
  IconArrowForwardUp,
  IconBlockquote,
  IconBold,
  IconCode,
  IconH1,
  IconH2,
  IconH3,
  IconH4,
  IconH5,
  IconH6,
  IconItalic,
  IconLineDashed,
  IconList,
  IconListNumbers,
  IconPilcrow,
  IconStrikethrough
} from '@tabler/icons-react';

const MenuBar = ({ editor, floating = false }) => {
  return (
    <Box className="control-group" backgroundColor={floating ? 'white' : 'transparent'} paddingBottom={'5px'}>
      <Box className="button-group">
        <MenuButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          active={editor.isActive('bold')}
          icon={<IconBold size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          active={editor.isActive('italic')}
          icon={<IconItalic size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          active={editor.isActive('strike')}
          icon={<IconStrikethrough size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={
            !editor.can()
              .chain()
              .focus()
              .toggleCode()
              .run()
          }
          active={editor.isActive('code')}
          icon={<IconCode size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().setParagraph().run()}

          active={editor.isActive('paragraph')}
          icon={<IconPilcrow size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}

          active={editor.isActive('heading', { level: 1 })}
          icon={<IconH1 size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}

          active={editor.isActive('heading', { level: 2 })}
          icon={<IconH2 size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}

          active={editor.isActive('heading', { level: 3 })}
          icon={<IconH3 size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}

          active={editor.isActive('heading', { level: 4 })}
          icon={<IconH4 size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}

          active={editor.isActive('heading', { level: 5 })}
          icon={<IconH5 size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          active={editor.isActive('heading', { level: 6 })}
          icon={<IconH6 size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          active={editor.isActive('bulletList')}
          icon={<IconList size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          active={editor.isActive('orderedList')}
          icon={<IconListNumbers size={18} stroke={2} />}
        />
        <MenuButton
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          active={editor.isActive('blockquote')}
          icon={<IconBlockquote size={18} stroke={2} />}
        />

        <MenuButton
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
          icon={<IconLineDashed size={18} stroke={2} />}
        />
        <br />
        <Box display={'flex'} flexWrap={true} alignItems={'center'}>

          <MenuButton
            onClick={() => editor.chain().focus().undo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .undo()
                .run()
            }
            icon={<IconArrowBackUp size={18} stroke={2} />}
          />
          <MenuButton
            onClick={() => editor.chain().focus().redo().run()}
            disabled={
              !editor.can()
                .chain()
                .focus()
                .redo()
                .run()
            }
            icon={<IconArrowForwardUp size={18} stroke={2} />}
          />
          <input
            type="color"
            onInput={event => editor.chain().focus().setColor(event.target.value).run()}
            value={editor.getAttributes('textStyle').color}
            data-testid="setColor"
          />
        </Box>

      </Box>
    </Box>
  );
};

const MenuButton = ({
                      onClick = () => {
                      },
                      disabled = false,
                      title = '',
                      icon = <></>,
                      active = false
                    }) => {

  return (
    <UIButton
      onClick={onClick}
      disabled={disabled}
      variant={'empty'}
      type={'editor-menu'}
      label={title}
      icon={icon}
      sxCustom={{
        backgroundColor: active ? '#04B2D9' : 'rgba(183,154,154,0.2)',
        fontWeight: active ? '700' : '400',
        color: active ? 'white' : 'black',
        borderRadius: 'md'
      }}
    />
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false
    }
  })
];


export const UIHtmlEditor = ({ content, onChange }) => {
  const [editorContent, setEditorContent] = useState(content);

  useEffect(() => {
    setEditorContent(content);
  }, [content]);

  let editor = useEditor({
    extensions,
    content: editorContent,
    onUpdate: ({ editor }) => {
      onChange(editor?.getHTML());
    }
  }, [editorContent]);

  return (
    <Box>
      <MenuBar editor={editor}/>
      <Box backgroundColor={'white'}
           paddingX={20}
           paddingY={10}
           height={'150px'}
           overflow={'auto'}
           borderRadius={'8px'}
           border={'1px solid rgb(227, 227, 227)'}
           resize={'vertical'}>
        <BubbleMenu editor={editor} className={'no-outline'}/>
        <FloatingMenu editor={editor}/>
        <EditorContent editor={editor} />
      </Box>
    </Box>
  );
};

export default UIHtmlEditor;
