import {
  DELETE_COMMENT_ADMIN,
  DELETE_POST_ADMIN,
  GET_SOCIAL_POST_ADMIN,
  LIST_SOCIAL_USER_POSTS_ADMIN,
  LIST_SOCIAL_USERS_ADMIN
} from './graphql/queries';
import { useQuery } from '../useQuery';

/** @format */
export const useSocial = () => {
  const { getDataGQL: listSocialUsersAdminPaginated } = useQuery(LIST_SOCIAL_USERS_ADMIN);
  const { getDataGQL: getSocialPostAdmin } = useQuery(GET_SOCIAL_POST_ADMIN);
  const { getDataGQL: listSocialUserPostsAdminPaginated } = useQuery(LIST_SOCIAL_USER_POSTS_ADMIN);
  const  {getDataGQL: deleteCommentAdmin} = useQuery(DELETE_COMMENT_ADMIN);
  const  {getDataGQL: deletePostAdmin} = useQuery(DELETE_POST_ADMIN);

  const onListSocialUsersAdminPaginated = async (data) => {
    const response = await listSocialUsersAdminPaginated({
      variables: data
    });
    if (response?.adminRoute?.listSocialUsersAdminPaginated) {
      return response?.adminRoute?.listSocialUsersAdminPaginated;
    }
    return null;
  };

  const onGetSocialPostAdmin = async (data) => {
    const response = await getSocialPostAdmin({
      variables: data
    });
    if (response?.adminRoute?.getSocialPostAdmin) {
      return response?.adminRoute?.getSocialPostAdmin;
    }
    return null;
  }
  const onListSocialUserPostsAdminPaginated = async (data) => {
    const response = await listSocialUserPostsAdminPaginated({
      variables: data
    });
    if (response?.adminRoute?.listSocialUserPostsAdminPaginated) {
      return response?.adminRoute?.listSocialUserPostsAdminPaginated;
    }
    return null;
  }

  const onDeleteCommentAdmin = async (data) => {
    const response = await deleteCommentAdmin({
      variables: data
    });
    if (response?.adminRoute?.deleteCommentAdmin) {
      return response?.adminRoute?.deleteCommentAdmin;
    }
    return null;
  }

  const onDeletePostAdmin = async (data) => {
    const response = await deletePostAdmin({
      variables: data
    });
    if (response?.adminRoute?.deletePostAdmin) {
      return response?.adminRoute?.deletePostAdmin;
    }
    return null;
  }

  return {
    onListSocialUsersAdminPaginated,
    onGetSocialPostAdmin,
    onListSocialUserPostsAdminPaginated,
    onDeleteCommentAdmin,
    onDeletePostAdmin
  };
};
