import gql from 'graphql-tag';

export const LIST_PRODUCTS_ADMIN = gql`
  query listProductsAdmin {
    adminRoute {
      listProductsAdmin {
        id
        name
        description
        color_id
        color {
          id
          code
        }
      }
    }
  }
`;

export const LIST_PRODUCTS_ADMIN_PAGINATED = gql`
  query listProductsAdminPaginated($filters: mixedFilters) {
    adminRoute {
      listProductsAdminPaginated(filters: $filters) {
        rows{
          id
          name
          description
          color_id
          color {
            id
            code
          }
        }
      }
    }
  }
`;

export const LIST_PRODUCTS_COLORS_ADMIN = gql`
  query listProductsColorAdmin {
    adminRoute {
      listProductsColorsAdmin {
        id
        code
      }
    }
  }
`;

export const CREATE_PRODUCTS_ADMIN = gql`
  query createProductAdmin($product_data: ProductInput!) {
    adminRoute {
      createProductAdmin(product_data: $product_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_PRODUCTS_ADMIN = gql`
  query updateProductAdmin($product_data: ProductInput!, $product_id: ID!) {
    adminRoute {
      updateProductAdmin(product_id: $product_id, product_data: $product_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_PRODUCTS_ADMIN = gql`
  query deleteProductAdmin($product_id: ID!) {
    adminRoute {
      deleteProductAdmin(product_id: $product_id) {
        response
        responseStatus
      }
    }
  }
`;
