import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './custom-products.css';
import { IconEdit } from '@tabler/icons-react';
import ModalProductEdit from './modalProductEdit';
import { useProducts } from '../../../hooks/Products/useProducts';
import { UIButton, UIButtonFloating } from '../../utilities/Button';
import { UIPageHeader } from '../../utilities/Headers';
import UITable from '../../utilities/Table';

const ProductsView = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListProductsAdminPaginated } = useProducts();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setSelectedProduct(null);
    setForceUpdate(true);
  }, []);

  const prepareValues = (products) =>
    products?.map((product) => {
      return {
        product: product,
        productEl: (
          <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
            {product?.name}
          </Typography>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedProduct(product);
                setEditMode(true);
              }}
              icon={
                <IconEdit
                  cursor={'pointer'}
                />
              }
            />
          </Box>
        )
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalProductEdit
        modalOpen={editMode}
        handleModalClose={handleModalClose}
        product={selectedProduct}
        setEditMode={setEditMode}
      />

      <UIButtonFloating
        onClick={() => {
          setSelectedProduct(null);
          setEditMode(true);
        }} />

      <UIPageHeader
        title={'Prodotti'}
        subtitle={
          'Gestisci qui i prodotti presenti in applicazione e disponibili per i medici. Puoi attivarli o disattivarli per i medici.'
        }
      />

      <UITable
        columns={[
          {
            name: 'Prodotto',
            value: 'productEl',
            size: 120
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri prodotti da mostrare'}
        infiniteScrollFunction={onListProductsAdminPaginated}
      />
    </Box>
  );
};
export default ProductsView;
