import { IconCheck } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIButton } from '../../utilities/Button';
import { useSocial } from '../../../hooks/Social/useSocial';
import { Box } from '@chakra-ui/react';
import {  UiSocialPostPreview } from '../../utilities/SocialPost';
import ModalEditPost from './modalEditPost';
import { SectionHeader } from '../../utilities/Headers';

const ModalViewPosts = ({ modalOpen, handleModalClose, user }) => {
  const [userData, setUserData] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const handleModalCloseInternal = () => {
    setPageLoading(true);
    handleModalClose();
  };

  const { onListSocialUserPostsAdminPaginated } = useSocial();

  const listPosts = async () => {
    return await onListSocialUserPostsAdminPaginated({
      filters: {
        author_uuid: userData?.uuid,
        limit: 900
      }
    });
  };

  useEffect(() => {
    if (!modalOpen) return;
    setUserData(user);
  }, [modalOpen]);

  useEffect(() => {
    if (!userData) return;
    listPosts().then((response) => {
      setPosts(response?.rows);
      setPageLoading(false);
    });
  }, [userData]);

  const modalEditClose = () => {
    setEditMode(false);
    setPageLoading(true);
    listPosts().then((response) => {
      setPosts(response?.rows);
      setPageLoading(false);
    });
  }

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <ModalEditPost modalOpen={editMode} handleModalClose={modalEditClose} post_uuid={selectedPost?._id} />

      <UiModalInternal onClose={handleModalCloseInternal} isLoading={pageLoading}>

        <UIModalBody
          sx={{
            paddingTop: '50px',
            bgcolor: 'transparent',
          }}
        >
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            gap={'10px'}
            justifyContent={'flex-start'}
          >
            <SectionHeader
              title={`Posts di ${userData?.social_nickname}`}
              sx={{
                width: '100%',
                textAlign: 'center'
              }}
            />
            {posts?.map((post, key) => (
              <UiSocialPostPreview key={key} post={post} onClick={() => {
                setSelectedPost(post);
                setEditMode(true);
              }}/>
            ))}


          </Box>


          <UIModalButtons>
            <UIButton type={'submit'} onClick={() => submitForm()}
                      icon={<IconCheck stroke={'1.64'} size={'26px'} />} />


          </UIModalButtons>
        </UIModalBody>

      </UiModalInternal>
    </UIModal>
  );
};
export default ModalViewPosts;
