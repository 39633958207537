import React, { useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { IconEdit, IconPlus } from '@tabler/icons-react';
import { useArticles } from '../../../hooks/Articles/useArticles';
import ModalEdit from './modalEdit';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';

const ArticlesView = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListArticlesAdmin } = useArticles();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setForceUpdate(true);
  }, []);

  const prepareValues = (articles) =>
    articles?.map((article) => {
      return {
        article: article,
        titleEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {article?.title}
            </Typography>
          </Box>
        ),
        slugEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {article?.slug}
            </Typography>
          </Box>
        ),
        tagsEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {article?.tags}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box
            onClick={() => {
              setSelectedArticle(article);
              setEditMode(true);
            }}
          >
            <IconEdit cursor={'pointer'} />
          </Box>
        )
      };
    });

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bgcolor={'#2c3e50'}
        p={'10px'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        onClick={() => {
          setSelectedArticle(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>
      <ModalEdit modalOpen={editMode} handleModalClose={handleModalClose} article={selectedArticle} />

      <UIPageHeader
        title={'Articoli'}
        subtitle={
          'Gestisci qui i tuoi articoli. In questa lista appariranno tutti gli articoli che hai creato. Clicca sull\'icona dell\'editor per modificarli.'
        }
      />

      <SectionHeader title={'Ricerca articolo'} subtitle={`E' possibile cercare per titolo, tags o slug.`} />
      <UITable
        columns={[
          {
            name: 'Titolo',
            value: 'titleEl',
            size: 400
          },
          {
            name: 'Slug',
            value: 'slugEl',
            size: 400
          },
          {
            name: 'Tags',
            value: 'tagsEl',
            size: 400
          },
          {
            name: 'Azioni',
            value: 'actionsEl',
            size: 100
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        infiniteScrollEndMessage={'Non ci sono altri articoli da mostrare'}
        infiniteScrollFunction={onListArticlesAdmin}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    </Box>
  );
};
export default ArticlesView;
