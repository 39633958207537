import React, { useCallback, useState } from 'react';
import { useTreatments } from '../../../hooks/Treatments/useTreatments';
import { Box, Typography } from '@mui/material';
import './custom-treatments.css';
import { IconCheckbox, IconEdit } from '@tabler/icons-react';
import ModalTreatmentEditAdmin from './modalTreatmentEditAdmin';
import { UIPageHeader } from '../../utilities/Headers';
import UITable from '../../utilities/Table';
import { UIButton, UIButtonFloating } from '../../utilities/Button';

const TreatmentsManageView = () => {
  const [selectedTreatment, setSelectedTreatment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const { onListTreatmentsAdminPaginated } = useTreatments();

  const handleModalClose = useCallback(() => {
    setEditMode(false);
    setSelectedTreatment(null);
    setForceUpdate(true);
  }, []);

  const prepareValues = (treatments) =>
    treatments?.map((treatment) => {
      return {
        treatment: treatment,
        treatmentEl: (
          <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
            {treatment?.name}
          </Typography>
        ),
        activeEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'start'}>
            {treatment?.active && <IconCheckbox />}
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedTreatment(treatment);
                setEditMode(true);
              }}
              icon={
                <IconEdit
                  cursor={'pointer'}

                />
              }
            />
          </Box>
        )
      };
    });

  return (
    <Box className="treatments-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalTreatmentEditAdmin
        modalOpen={editMode}
        handleModalClose={handleModalClose}
        treatment={selectedTreatment}
        setEditMode={setEditMode}
      />

      <UIButtonFloating
        onClick={() => {
          setSelectedTreatment(null);
          setEditMode(true);
        }} />

      <UIPageHeader
        title={'Trattamenti'}
        subtitle={
          'Gestisci qui i trattamenti presenti in applicazione e disponibili per i medici. Puoi attivarli o disattivarli per i medici e decidere se renderli prenotabili o meno.'
        }
      />

      <UITable
        columns={[
          {
            name: 'Trattamento',
            value: 'treatmentEl',
            size: 120
          },
          {
            name: 'Attivo per i dottori',
            value: 'activeEl',
            size: 100
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        enableCustomFilters={true}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri trattamenti da mostrare'}
        infiniteScrollFunction={onListTreatmentsAdminPaginated}
      />
    </Box>
  );
};
export default TreatmentsManageView;
