import { Box } from '@mui/material';
import { IconStarFilled } from '@tabler/icons-react';

const ReviewStars = ({ rating }) => {

  return (
    <Box>
      {[...Array(5)].map((star, index) => {
        return (
          <IconStarFilled key={index} style={{
            color: index < rating ? '#FFCC00' : '#E0E0E0',
          }} />
        );
      })}
    </Box>
  );
};

export default ReviewStars;
