import { Box, Image } from '@chakra-ui/react';
import Lightbox from 'yet-another-react-lightbox';
import React, { useEffect } from 'react';
import UITypography from './Typography';
import { UIButton } from './Button';
import { IconTrash } from '@tabler/icons-react';

export const UiSocialPostPreview = ({
                                      post, onClick = () => {
  }
                                    }) => {
  return (
    <Box
      rounded={8}
      height={'200px'}
      width={'calc(36% - 20px)'}
      overflow={'hidden'}
      cursor={'pointer'}
      onClick={onClick}
      border={'1px solid gray'}
    >
      <Image objectFit={'cover'} src={post?.image} width={'100%'} height={'100%'} />
    </Box>
  );
};

export const UiSocialPost = ({ post }) => {
  const [open, setOpen] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(-1);

  return (
    <Box width={'100%'}>
      <Box width={'100%'} height={'250px'}>
        <Image cursor={'pointer'} objectFit={'cover'} src={post?.image} width={'100%'} height={'100%'} rounded={8}
               onClick={() => {
                 setImageIndex(0);
                 setOpen(true);
               }} />
        <Lightbox index={imageIndex} slides={[{ src: post?.image }]} open={open} close={() => {
          setImageIndex(-1);
          setOpen(false);
        }} />
      </Box>
    </Box>
  );
};

export const UiSocialPostComment = ({ comment, onDelete = () => {} }) => {
  return (
    <Box backgroundColor={'#ECECEC'} rounded={24} p={'20px'} my={'10px'} position={'relative'}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Box>
          <UITypography>
            {(comment?.user?.doctor) ? `${comment?.user?.social_nickname} (${comment?.user?.name} ${comment?.user?.surname})` : `${comment?.user?.name} ${comment?.user?.surname}`}
          </UITypography>
        </Box>
        <Box >
          <UIButton
            type={'icon'}
            variant={'empty'}
            icon={<IconTrash stroke={1.64} />}
            onClick={() => onDelete(comment)}
            sxCustom={{
              width: '20px',
              height: '20px',
              p:0,
              borderRadius: '5px'
            }}
          />
        </Box>
      </Box>

      <Box>
        <UITypography variant={'Oxygen300'} size={'13px'} sxCustom={{marginTop:'5px', color:'#000'}} >
          {comment?.comment}
        </UITypography>
      </Box>
    </Box>
  );
};

export const UiSocialPostComments = ({ comments, comment_uuid = null, replyLevel = 0, onDelete = () => {} }) => {
  let [commentsThree, setCommentsThree] = React.useState([]);
  let [commentsData] = React.useState(comments);

  useEffect(() => {
    let comments_analyze;

    if(comment_uuid){
      comments_analyze = commentsData.filter((comment) => comment?.father_comment?._id === comment_uuid);
    } else {
     comments_analyze = commentsData.filter((comment) => !comment?.father_comment);
    }

    let comments_data = comments_analyze.map((comment) => {
      return {
        ...comment,
        replies: commentsData.filter((reply) => reply?.father_comment?._id === comment?._id)
      };
    });
    setCommentsThree(comments_data);
  }, [comments]);

  return (
    <>
      {commentsThree.map((comment) => (
        <Box key={comment?._id} my={'10px'} ml={replyLevel * 5}>
          <UiSocialPostComment comment={comment} onDelete={onDelete} />
          {comment.replies.length > 0 && (
            <UiSocialPostComments comments={comments} comment_uuid={comment?._id} replyLevel={replyLevel + 1} onDelete={onDelete} />
          )}
        </Box>
      ))}
    </>
  );
};
