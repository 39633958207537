import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useArticles } from '../../../hooks/Articles/useArticles';
import { UIModal, UIModalBody, UIModalButtons, UIModalHeader, UiModalInternal } from '../../utilities/Modal';
import { UICheckbox, UIInput, UIInputLabel } from '../../utilities/Input';
import { UIFormControlBox } from '../../utilities/Form';
import { UIHtmlEditor } from '../../utilities/Editor';
import { UIButton } from '../../utilities/Button';
import { IconCheck, IconTrash } from '@tabler/icons-react';
import { Box, Image } from '@chakra-ui/react';
import DropzoneFormikInput from '../../utilities/Dropzone';
import UITypography from '../../utilities/Typography';
import { UiDialog } from '../../utilities/Dialog';

const ModalEdit = ({ modalOpen, handleModalClose, article }) => {
  let [articleData, setArticleData] = useState(null);
  const [deleteMode, setDeleteMode] = useState(false);
  const [uuid, setUuid] = React.useState(uuidv4());
  const [contentText, setContentText] = useState('');
  const [mobileContentText, setMobileContentText] = useState('');
  const [articleMetadata, setArticleMetadata] = useState([]);

  useEffect(() => {
    setUuid(uuidv4());
  }, [article]);

  const { onGetArticleAdmin, onUpdateArticleAdmin, onCreateArticleAdmin, onDeleteArticleAdmin } = useArticles();

  const getArticle = async () => {
    onGetArticleAdmin({
      article_uuid: article.uuid
    }).then((res) => {
      setArticleData(res);
    });
  };

  useEffect(() => {
    if (!article) return;
    getArticle().then(() => {
    });
  }, [article]);

  useEffect(() => {
    if(!articleData) return;
    setContentText(articleData?.content);
    setMobileContentText(articleData?.mobile_content);
    setArticleMetadata(articleData?.metadata.map((m) => ({ name: m.name, content: m.content })));
  }, [articleData]);

  const handleSubmit = async (values) => {
    const { title, slug, cover, preview, tags, active, fixed } = values;

    if (articleData?.uuid) {
      onUpdateArticleAdmin({
        article_uuid: articleData?.uuid,
        article_data: {
          title,
          slug,
          content:contentText,
          mobile_content: mobileContentText,
          cover,
          preview,
          tags,
          fixed,
          active,
          metadata: articleMetadata
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: res?.responseStatus ?  '#00e676' : '#FF646C',
            color: '#ffffff'
          }
        });
        if (res?.responseStatus) {
          handleModalCloseInternal();
        }
      });
    } else {
      onCreateArticleAdmin({
        article_data: {
          uuid: uuid,
          title,
          slug,
          content: contentText,
          mobile_content: mobileContentText,
          cover,
          preview,
          tags,
          fixed,
          active,
          metadata: articleMetadata
        }
      }).then((res) => {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: res?.responseStatus ?  '#00e676' : '#FF646C',
            color: '#ffffff'
          }
        });
        if (res?.responseStatus) {
          handleModalCloseInternal();
        }
      });
    }
  };

  const handleDeleteArticle = () => {
    onDeleteArticleAdmin({
      article_uuid: articleData?.uuid
    }).then((res) => {
      if (res?.response) {
        toast(res?.response, {
          style: {
            fontSize: '14px',
            backgroundColor: '#00e676',
            color: '#ffffff'
          }
        });
      }
      handleModalCloseInternal();
    });
  }

  const handleModalCloseInternal = () => {
    setArticleData(null);
    setDeleteMode(false);
    setMobileContentText('');
    setContentText('');
    setArticleMetadata([]);
    handleModalClose();
  };

  const handleMetadataUpdate = (metadata, index, field) => {
    const newMetadata = [...articleMetadata];
    newMetadata[index][field] = metadata;
    setArticleMetadata(newMetadata);
  };

  const handleMetadataAdd = () => {
    const newMetadata = [...articleMetadata];
    newMetadata.push({ name: '', content: '' });
    setArticleMetadata(newMetadata);
  };

  const handleMetadataRemove = (index) => {
    const newMetadata = [...articleMetadata];
    newMetadata.splice(index, 1);
    setArticleMetadata(newMetadata);
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>

      <UiDialog
        type={'default'}
        variant={'cancel'}
        title={'Vuoi eliminare definitivamente l\'articolo?'}
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        onConfirm={handleDeleteArticle}
      />

      <Formik
        initialValues={{
          title: articleData?.title || '',
          slug: articleData?.slug || '',
          content: articleData?.content || '',
          mobile_content: articleData?.mobile_content || '',
          cover: articleData?.cover || '',
          preview: articleData?.preview || '',
          tags: articleData?.tags || '',
          fixed: articleData?.fixed || false,
          active: articleData?.active
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required('Inserisci un titolo'),
          slug: Yup.string().required('Inserisci uno slug univoco'),
          content: Yup.string().test({
            test: () => {
              return contentText?.length > 150;
            },
            message: 'Inserisci almeno 150 caratteri'
          }),
          mobile_content: Yup.string().test({
            test: () => {
              return mobileContentText?.length > 150;
            },
            message: 'Inserisci almeno 150 caratteri'
          }),
          cover: Yup.string().required('Inserisci un\'immagine'),
          preview: Yup.string().required('Inserisci un\'immagine'),
          tags: Yup.string().required('Inserisci un tag'),
          fixed: Yup.boolean(),
          active: Yup.boolean()
        })}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, submitForm, handleSubmit, touched, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} id={'article-form'}>
            <UiModalInternal onClose={handleModalCloseInternal} isLoading={article && articleData === null} containerSx={{
              width: '80%',
              maxWidth: '800px'
            }}>
              <UIModalHeader sx={{ border: 'none', p: '50px 0 0 0' }} />
              <UIModalBody
                sx={{
                  bgcolor: 'transparent',
                }}
              >

                <UIFormControlBox label={'Titolo'} error={Boolean(touched.title && errors.title)} error_message={errors?.title}>
                  <UIInput value={values.title} onChange={(e) => setFieldValue('title', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Slug'} error={Boolean(touched.slug && errors.slug)} error_message={errors?.slug}>
                  <UIInput value={values.slug} onChange={(e) => setFieldValue('slug', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox
                  label={'Contenuto Web'}
                  subtitle={'Contenuto visualizzato su desktop.'}
                  error={Boolean(touched.content && errors.content)}
                  error_message={errors?.content}
                  >
                  <UIHtmlEditor content={values?.content} onChange={(html) => setContentText(html)} />
                </UIFormControlBox>

                <UIFormControlBox
                  label={'Contenuto Mobile'}
                  subtitle={'Contenuto visualizzato su cellulare.'}
                  error={Boolean(touched.mobile_content && errors.mobile_content)}
                  error_message={errors?.mobile_content}
                >
                  <UIHtmlEditor content={values?.mobile_content}
                                onChange={(html) => setMobileContentText( html)} />
                </UIFormControlBox>

                <UIFormControlBox label={'Metadata'} error_message={errors?.metadata}>
                  {articleMetadata?.map((metadata, index) => (
                    <Box key={index} display={'flex'} flexWrap={'wrap'} gap={'20px'} mt={10}>
                      <UIFormControlBox sx={{ display: 'block', width: 'auto', p: 0 }}>
                        <UIInputLabel label={'Name'} htmlFor={'test'} />
                        <UIInput id={'test'} label={'Name'} value={metadata?.name}
                                 aria-describedby="base-name-helper-text"
                                 onChange={(e) => handleMetadataUpdate(e.target.value, index, 'name')}
                                 sx={{ color: 'red' }} />
                      </UIFormControlBox>
                      <UIFormControlBox sx={{ display: 'block', width: 'auto', p: 0 }}>
                        <UIInputLabel label={'Content'} htmlFor={'test1'} />
                        <UIInput id={'test1'} label={'Content'} value={metadata?.content}
                                 aria-describedby="base-name-helper-text"
                                 onChange={(e) => handleMetadataUpdate(e.target.value,index,'content')} sx={{ color: 'red' }} />
                      </UIFormControlBox>
                      <UIButton
                        sxCustom={{
                          bgcolor: '#FF646C',
                          color: 'white',
                          padding: '10px',
                          minWidth: 'auto'
                        }}
                        onClick={() => handleMetadataRemove(index)}
                        icon={<IconTrash stroke={1.64} size="25px" />
                        } />
                    </Box>
                  ))}

                  <Box mt={10}>
                    <UIButton onClick={handleMetadataAdd} label={'Aggiungi Metadata'} />
                  </Box>

                </UIFormControlBox>


                <UIFormControlBox label={'Immagine'} error={Boolean(touched.cover && errors.cover)} error_message={errors?.cover}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                    <Image src={values.cover} w={'100px'} h={'100px'} objectFit={'cover'} />
                    <DropzoneFormikInput
                      variant={'article_cover'}
                      setFieldValue={setFieldValue}
                      field={'cover'}
                      folder={'articles'}
                      label={'Carica'}
                      extra_data={{
                        article_uuid: articleData?.uuid ?? uuid
                      }}
                    />
                  </Box>
                </UIFormControlBox>

                <UIFormControlBox label={'Immagine'} error={Boolean(touched.preview && errors.preview)} error_message={errors?.preview}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0 10px'}>
                    <Image src={values.preview} w={'100px'} h={'100px'} objectFit={'cover'} />
                    <DropzoneFormikInput
                      variant={'article_preview'}
                      setFieldValue={setFieldValue}
                      field={'preview'}
                      folder={'articles'}
                      label={'Carica'}
                      extra_data={{
                        article_uuid: articleData?.uuid ?? uuid
                      }}
                    />
                  </Box>
                </UIFormControlBox>

                <UIFormControlBox label={'Tags'} error={Boolean(touched.tags && errors.tags)} error_message={errors?.tags}>
                  <UIInput value={values.tags} onChange={(e) => setFieldValue('tags', e.target.value)} />
                </UIFormControlBox>

                <UIFormControlBox>
                  <UICheckbox
                    label={<UITypography title={'In primo piano'} />}
                    name={'fixed'}
                    readOnly={true}
                    checked={values?.fixed}
                    onChange={(e) => setFieldValue('fixed', e.target.checked)}
                  />
                </UIFormControlBox>

                <UIFormControlBox>
                  <UICheckbox
                    label={<UITypography title={'Attivo'} />}
                    name={'active'}
                    readOnly={true}
                    checked={values?.active}
                    onChange={(e) => setFieldValue('active', e.target.checked)}
                  />
                </UIFormControlBox>


              </UIModalBody>


              <UIModalButtons>
                <UIButton type={'submit'} onClick={() => submitForm()}
                          icon={<IconCheck stroke={'1.64'} size={'26px'} />} />
                <UIButton
                  type={'submit'}
                  onClick={() => {
                    setDeleteMode(true);
                  }}
                  icon={<IconTrash stroke={1.64} size="25px" />}
                  sxCustom={{
                    bgcolor: '#FF646C',
                  }}
                />
              </UIModalButtons>
            </UiModalInternal>

          </form>
        )}
      </Formik>
    </UIModal>
  );
};

export default ModalEdit;


