import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, FormControl, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import './custom-chats.css';
import { useChats } from '../../../hooks/Chats/useChats';
import { useParams } from 'react-router';
import { useAuthentication } from '../../../utils/useAuthentication';
import moment from 'moment/moment';
import * as Yup from 'yup';
import { Formik } from 'formik';
import SendImage from 'assets/images/icons/sendMessage.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconChevronLeft, IconUsers, IconBan, IconTrash } from '@tabler/icons-react';
import { Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import SkinboostImage from '../../../assets/images/skinboost_message.svg';
import ModalUserDetails from '../modals/modalUserDetails';
import ModalDoctorDetails from '../modals/modalDoctorDetails';
import { UiDialog } from '../../utilities/Dialog';

const ChatDetailView = () => {
  let user_data = useAuthentication();
  let user = user_data?.user?.account_data;
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [userDetailsMode, setUserDetailsMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const { chat_uuid } = useParams();
  const [isToAdmin, setIsToAdmin] = useState(false);

  const { onGetChatDoctor, onSendChatMessage, onDeleteChatDoctor, onBlockChatDoctor } = useChats();

  const getChat = useCallback(
    (manual_page = null) => {
      onGetChatDoctor({
        chat_uuid,
        limit: 10,
        page: manual_page ?? page
      }).then((res) => {
        setIsToAdmin(res?.to_admin && !user.admin);
        setData(res);
        if (manual_page) {
          setMessages([...(res?.messages ?? [])]);
          setPage(1);
        } else {
          if (res?.messages?.length === 0) {
            setHasMore(false);
            return;
          }
          setMessages([...messages, ...(res?.messages ?? [])]);
        }
      });
    },
    [page]
  );

  const fetchMoreData = () => {
    setTimeout(() => {
      setPage(page + 1);
    }, 500);
  };

  useEffect(() => {
    getChat();
  }, [page]);

  const handleSubmit = async (values) => {
    onSendChatMessage({
      chat_uuid,
      message: values?.message
    }).then(() => {
      setMessages([]);
      setHasMore(true);
      getChat(1);
    });
  };

  const handleBlockChat = async () => {
    onBlockChatDoctor({
      chat_uuid
    }).then(() => {
      getChat();
    });
  };

  const handleDeleteChat = async () => {
    onDeleteChatDoctor({
      chat_uuid
    }).then(() => {
      navigate('/chat');
    });
  };

  return (
    <>
      <UiDialog
        open={deleteMode}
        onClose={() => setDeleteMode(false)}
        title={'Vuoi eliminare la chat?'}
        onConfirm={handleDeleteChat}
        labelConfirm={'Elimina'}
      />

      {!user?.admin ? (
        <ModalUserDetails modalOpen={userDetailsMode} handleModalClose={() => setUserDetailsMode(false)} userRow={data?.patient} />
      ) : (
        <ModalDoctorDetails handleModalClose={() => setUserDetailsMode(false)} modalOpen={userDetailsMode} doctor={data?.patient} />
      )}

      <Box
        height={'112px'}
        bgcolor={data?.blocked ? '#FFE1E1' : '#EEF2F6'}
        display={'flex'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        p={'10px'}
        boxSizing={'border-box'}
        width={'calc(100% + 32px)'}
        ml={'-16px'}
        position={'relative'}
      >
        <Box
          width={'50px'}
          height={'50px'}
          borderRadius={'100%'}
          bgcolor={'#FFF'}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          color={'#200E32'}
          onClick={() => navigate('/chat')}
        >
          <IconChevronLeft stroke={'1.5px'} width={'24px'} height={'24px'} />
        </Box>
        {!isToAdmin ? (
          <>
            <Box ml={'10px'}>
              <Image src={data?.patient?.profile_pic} borderRadius={'100%'} boxSize={'48px'} />
            </Box>
            <Box ml={'10px'}>
              <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'} textAlign={'left'}>
                {data?.patient?.name} {data?.patient?.surname}
              </Typography>
              <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'} textAlign={'left'} mt={'5px'}>
                {data?.patient?.email}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <Box ml={'10px'}>
              <Image src={SkinboostImage} borderRadius={'100%'} boxSize={'48px'} />
            </Box>
            <Box ml={'10px'}>
              <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'} textAlign={'left'}>
                Skinboost
              </Typography>
              <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'} textAlign={'left'} mt={'5px'}>
                skinboostapp@gmail.com
              </Typography>
            </Box>
          </>
        )}

        {!isToAdmin && (
          <Box
            position={'absolute'}
            right={'0'}
            bottom={'-14px'}
            height={'38px'}
            width={'100%'}
            display={'flex'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <Box
              variant={'contained'}
              borderRadius={'100%'}
              width={'38px'}
              minWidth={'38px'}
              height={'38px'}
              bgcolor={'#FECE2E'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'#FFF'}
              mr={'10px'}
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => {
                setUserDetailsMode(true);
              }}
            >
              <IconUsers width={'16px'} height={'16px'} />
            </Box>
            <Box
              variant={'contained'}
              borderRadius={'100%'}
              width={'38px'}
              minWidth={'38px'}
              height={'38px'}
              bgcolor={data?.blocked ? '#06E775' : '#FF0000'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'#FFF'}
              mr={'10px'}
              sx={{
                cursor: 'pointer'
              }}
              onClick={handleBlockChat}
            >
              <IconBan width={'18px'} height={'18px'} />
            </Box>
            <Box
              variant={'contained'}
              borderRadius={'100%'}
              width={'38px'}
              minWidth={'38px'}
              height={'38px'}
              bgcolor={'#FF646C'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              color={'#FFF'}
              mr={'10px'}
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => setDeleteMode(true)}
            >
              <IconTrash width={'18px'} height={'18px'} />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        id="scrollableDiv"
        className="chats-view"
        width={'calc(100% + 32px)'}
        ml={'-16px'}
        height={'calc(100vh - 300px)'}
        overflow={'auto'}
        p={'10px'}
        boxSizing={'border-box'}
        display={'flex'}
        flexDirection={'column-reverse'}
        bgcolor={'#FFF'}
      >
        <InfiniteScroll
          dataLength={messages.length}
          next={fetchMoreData}
          style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={hasMore}
          loader={
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <CircularProgress
                sx={{
                  color: '#FFC433'
                }}
              />
            </Box>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            messages?.length === 0 ? (
              <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} textAlign={'center'} mt={'5px'}>
                Scrivi un messaggio per avviare la chat
              </Typography>
            ) : (
              <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'13px'} textAlign={'center'} mt={'5px'}>
                Fine cronologia messaggi
              </Typography>
            )
          }
        >
          {messages?.map((row, index) => (
            <Box
              key={index}
              width={'95%'}
              m={'5px auto'}
              display={'flex'}
              justifyContent={user?.id === row?.sender_id ? 'flex-end' : 'flex-start'}
              flexDirection={'row'}
            >
              <Box width={'70%'}>
                <Typography
                  fontFamily={'Oxygen'}
                  fontWeight={'700'}
                  fontSize={'11px'}
                  textAlign={user?.id === row?.sender_id ? 'right' : 'left'}
                >
                  {moment(Number(row?.createdAt)).format('DD/MM/YYYY HH:mm')}
                </Typography>
                <Box
                  my={'2px'}
                  borderRadius={'8px'}
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                  px={'20px'}
                  boxSizing={'border-box'}
                  color={'#101828'}
                  underline={'none'}
                  py={'10px'}
                  bgcolor={user?.id === row?.sender_id ? '#FDF2B5' : '#ECECEC'}
                >
                  <Typography
                    fontFamily={'Oxygen'}
                    fontWeight={'400'}
                    fontSize={'15px'}
                    textAlign={'left'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                  >
                    {row?.message}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </InfiniteScroll>
      </Box>

      {!data?.blocked ? (
        <Formik
          initialValues={{
            message: ''
          }}
          validationSchema={Yup.object().shape({
            message: Yup.string().max(255).required('Messaggio richiesto')
          })}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values).then(() => {
              resetForm();
            });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Box
                bgcolor={'#ECECEC'}
                height={'100px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={'10px'}
                width={'calc(100% + 32px)'}
                ml={'-16px'}
              >
                <Box width={'60%'}>
                  <FormControl fullWidth error={Boolean(touched.message && errors.message)}>
                    <OutlinedInput
                      id="form-message"
                      type="message"
                      value={values.message}
                      name="message"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      inputProps={{}}
                      sx={{
                        height: '50px',
                        minHeight: '50px'
                      }}
                    />
                    {touched.message && errors.message && (
                      <FormHelperText error id="standard-weight-helper-text-email-login">
                        {errors.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Box>
                <Button variant={'text'} type={'submit'}>
                  <Box width={'35px'} height={'35px'}>
                    <img src={SendImage} alt="Invia" width={'35px'} height={'35px'} />
                  </Box>
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Box
          bgcolor={'#ECECEC'}
          height={'100px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={'10px'}
          width={'calc(100% + 32px)'}
          ml={'-16px'}
        >
          <Typography
            fontFamily={'Oxygen'}
            fontWeight={'700'}
            fontSize={'16px'}
            textAlign={'center'}
            mt={'5px'}
            color={'#FF0000'}
            textTransform={'uppercase'}
            display={'flex'}
            alignItems={'center'}
            mr={1}
          >
            <IconBan stroke={2} />
          </Typography>
          <Typography
            fontFamily={'Oxygen'}
            fontWeight={'700'}
            fontSize={'16px'}
            textAlign={'center'}
            mt={'5px'}
            color={'#FF0000'}
            textTransform={'uppercase'}
            display={'flex'}
            alignItems={'center'}
          >
            Chat bloccata
          </Typography>
        </Box>
      )}
    </>
  );
};
export default ChatDetailView;
