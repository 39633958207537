// assets
import { IconBrandWechat } from '@tabler/icons-react';
// constant
const icons = { IconBrandWechat };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const social = {
  id: 'social',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'social_list',
      title: 'Social',
      type: 'collapse',
      icon: icons.IconBrandWechat,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'shop_users',
          title: 'Posts',
          type: 'item',
          url: '/social/users',
          icon: icons.IconBrandWechat,
          breadcrumbs: false,
          permission: ['ADMIN']
        }
      ]
    }
  ]
};

export default social;
