import { Box, Checkbox, FormControlLabel, InputLabel, OutlinedInput, TextareaAutosize } from '@mui/material';
import React from 'react';
import UITypography from './Typography';
import { IconArrowBadgeUp } from '@tabler/icons-react';

export const UIInput = ({ id, type = 'text', value, onChange = () => {}, label = null, ...rest }) => {
  return <OutlinedInput id={id} type={type} value={value} onChange={onChange} label={label} {...rest} />;
};

export const UIInputLabel = ({ label = null, htmlFor, ...rest }) => {
  return <InputLabel htmlFor={htmlFor} {...rest}>{label}</InputLabel>;
};

export const UITextarea = ({
  value,
  name = null,
  minRows = 1,
  maxRows = 10,
  expandable = false,
  onChange = () => {},
  label = '',
  placeholder = '',
  resize = 'none',
  readOnly = false,
  sx
}) => {
  if (!expandable) {
    maxRows = 3;
    minRows = 3;
  }

  return (
    <TextareaAutosize
      overflow={'visible'}
      minRows={minRows}
      maxRows={maxRows}
      value={value}
      name={name}
      onChange={onChange}
      label={label}
      height={'auto'}
      style={{
        bgcolor: '#FFF',
        padding: '12px',
        borderRadius: '8px',
        border: '1px solid #E3E3E3',
        color: '#101828',
        outline: 'none',
        fontFamily: 'Oxygen',
        fontWeight: 300,
        fontSize: '12px',
        overflow: 'visible',
        minHeight: resize !== 'none' ? '100px' : 'unset',
        height: resize !== 'none' ? 'auto' : 'unset',
        resize,
        ...sx
      }}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  );
};

export const UICheckbox = ({ name, checked = false, readOnly = false, onChange = () => {}, label = <></>, color = 'primary', sx = {} }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          readOnly={readOnly}
          name={name}
          color={color}
          sx={{
            color: readOnly ? '#C5C7CD' : '#FECE2E',
            '&.Mui-checked': {
              color: '#FECE2E'
            },
            ...sx
          }}
        />
      }
      label={label}
    />
  );
};

export const UISlider = ({ maxValue = 10, minValue = 0, value, subtitles = [] }) => {
  return (
    <Box
      sx={{
        width: '350px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <UITypography variant={'Oxygen300'} title={String(minValue)} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '14px'
          }}
        >
          {Array.from(Array(maxValue + 1).keys())
            ?.slice(1)
            ?.slice(minValue)
            ?.map((i) => (
              <Box key={i}>
                <Box
                  sx={{
                    borderRadius: '100%',
                    bgcolor: i <= value ? '#FECE2E' : '#D9D9D9',
                    width: '12px',
                    height: '12px'
                  }}
                />
                <Box key={i} height={'10px'}>
                  {subtitles?.find((s) => s.value === i) && <IconArrowBadgeUp size={12} color={'#D9D9D9'} />}
                </Box>
              </Box>
            ))}
        </Box>

        <UITypography variant={'Oxygen300'} title={String(maxValue)} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px'
        }}
      >
        <UITypography variant={'Oxygen300'} title={''} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '10px',
            width: '86%',
            textTransform: 'uppercase',
            mr: '8px',
            mt: '5px'
          }}
        >
          {subtitles.map((value, index) => (
            <UITypography
              key={index}
              variant={'Oxygen300'}
              sxCustom={{
                textAlign: value?.align ?? 'left'
              }}
              size={'10px'}
              title={value?.label}
            />
          ))}
        </Box>

        <UITypography variant={'Oxygen300'} title={''} />
      </Box>
    </Box>
  );
};
