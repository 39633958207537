import { IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import 'moment/locale/it';
import { UIModal, UIModalBody, UIModalButtons, UiModalInternal } from '../../utilities/Modal';
import { UIButton } from '../../utilities/Button';
import { useSocial } from '../../../hooks/Social/useSocial';
import { Box } from '@chakra-ui/react';
import { UiSocialPost, UiSocialPostComments } from '../../utilities/SocialPost';
import { SectionHeader } from '../../utilities/Headers';
import { UITextarea } from '../../utilities/Input';
import { UiDialog } from '../../utilities/Dialog';

const ModalEditPost = ({ modalOpen, handleModalClose, post_uuid }) => {
  const [postUuid, setPostUuid] = useState(null);
  const [postData, setPostData] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [deleteCommentMode, setDeleteCommentMode] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);
  const [deletePostMode, setDeletePostMode] = useState(null);

  const handleModalCloseInternal = () => {
    setPageLoading(true);
    setPostData([]);
    setPostUuid(null);
    setDeleteCommentMode(false);
    setDeletePostMode(false);
    handleModalClose();
  };

  const { onGetSocialPostAdmin, onDeleteCommentAdmin, onDeletePostAdmin } = useSocial();

  const getPost = async () => {
    return await onGetSocialPostAdmin({
      post_uuid: postUuid
    });
  };

  const deleteCommentAdmin = async (comment) => {
    return await onDeleteCommentAdmin({
      comment_uuid: comment?._id
    });
  }

  const deletePostAdmin = async () => {
    return await onDeletePostAdmin({
      post_uuid: postData?._id
    });
  }

  useEffect(() => {
    if (!modalOpen) return;
    setPostUuid(post_uuid);
  }, [modalOpen]);

  useEffect(() => {
    if (!postUuid) return;
    getPost().then((response) => {
      setPostData(response);
      setPageLoading(false);
    });
  }, [postUuid]);

  const handleOnDelete = (comment) => {
    setDeleteCommentMode(true);
    setSelectedComment(comment);
  };

  return (
    <UIModal open={modalOpen} onClose={handleModalCloseInternal} allowFullScreen={true} className={'modal'}>
      <>
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi eliminare questo commento?'}
          subtitle={'Eliminando un commento che ha delle risposte, anche le risposte scompariranno.'}
          open={deleteCommentMode}
          onClose={() => setDeleteCommentMode(false)}
          onConfirm={() => {
            setPageLoading(true);
            deleteCommentAdmin(selectedComment).then(() => {
              getPost().then((response) => {
                setPostData(response);
                setPageLoading(false);
              });
            });
            setDeleteCommentMode(false);
          }}
        />
        <UiDialog
          type={'default'}
          variant={'alert'}
          title={'Vuoi eliminare questo post?'}
          subtitle={'Eliminando un post che ha dei commenti, anche questi scompariranno e non sarà possibile recuperare il post o i commenti.'}
          open={deletePostMode}
          onClose={() => setDeletePostMode(false)}
          onConfirm={() => {
            deletePostAdmin().then(() => {
              setDeletePostMode(false);
              handleModalCloseInternal();
            });
          }}
        />
      </>

      <UiModalInternal onClose={handleModalCloseInternal} isLoading={pageLoading}>

        <UIModalBody
          sx={{
            paddingTop: '50px',
            bgcolor: 'transparent'
          }}
        >
          <Box
          >
            <SectionHeader
              title={`Post di ${postData?.author?.social_nickname}`}
              sx={{
                width: '100%',
                textAlign: 'center'
              }}
            />
            <UiSocialPost post={postData} />


            <Box id={'descrizione'}>
              <SectionHeader
                title={'Descrizione'}
                sx={{
                  marginY: '2px'
                }}
              />
              <UITextarea
                sx={{
                  width: '100%',
                  height: '0px',
                  cursor: 'default'
                }}
                readOnly={true}
                value={postData?.description}
              />
            </Box>

            <Box id={'tags'}>
              <SectionHeader
                title={'Tags'}
                sx={{
                  marginY: '2px'
                }}
              />
              <UITextarea
                sx={{
                  width: '100%',
                  height: '0px',
                  cursor: 'default'
                }}
                readOnly={true}
                value={postData?.tags}
              />
            </Box>


            <Box id={'commenti'}>
              <SectionHeader
                title={'Commenti'}
              />
              <Box>
                <UiSocialPostComments comments={postData?.comments} onDelete={handleOnDelete} />
              </Box>
            </Box>

          </Box>


          <UIModalButtons>
            <UIButton
              type={'button'}
              variant={'cancel'}
              onClick={() => setDeletePostMode(true)}
              icon={<IconTrash stroke={'1.64'} size={'26px'} />}
            />


          </UIModalButtons>
        </UIModalBody>

      </UiModalInternal>
    </UIModal>
  );
};
export default ModalEditPost;
