import gql from 'graphql-tag';

export const LIST_SOCIAL_USERS_ADMIN = gql`
  query listSocialUsersAdminPaginated($filters: mixedFilters!) {
    adminRoute {
      listSocialUsersAdminPaginated(filters: $filters) {
        count
        rows {
          id
          uuid
          name
          surname
          social_nickname
          sex
          phone
          phone_country {
            id
            name
            dial_code
          }
          email
          profile_pic
          doctor_specialization
          instagram_link
          formation
          about_me
          active
          first_login
        }
      }
    }
  }
`;

export const GET_SOCIAL_POST_ADMIN = gql`
  query getSocialPostAdmin($post_uuid: ID!) {
    adminRoute {
      getSocialPostAdmin(post_uuid: $post_uuid) {
        _id
        image
        description
        tags
        appointments_link
        likes
        liked
        user_uuid
        author {
          social_nickname
        }
        comments {
          _id
          comment
          user_uuid
          father_comment {
            _id
          }
          user {
            name
            surname
            social_nickname
            doctor
          }
        }
        total_comments
        active
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

export const LIST_SOCIAL_USER_POSTS_ADMIN = gql`
  query listSocialUsersAdminPaginated($filters: socialFilters!) {
    adminRoute {
      listSocialUserPostsAdminPaginated(filters: $filters) {
        count
        rows {
          _id
          image
          description
          tags
          appointments_link
          likes
          liked
          user_uuid
          comments {
            _id
            comment
            user_uuid
          }
          total_comments
          active
          createdAt
          updatedAt
          deletedAt
        }
      }
    }
  }
`;

export const DELETE_COMMENT_ADMIN = gql`
  query deleteCommentAdmin($comment_uuid: ID!) {
    adminRoute {
      deleteCommentAdmin(comment_uuid: $comment_uuid){
        response
        responseStatus
      }
    }
  }
`;
export const DELETE_POST_ADMIN = gql`
  query deletePostAdmin($post_uuid: ID!) {
    adminRoute {
      deletePostAdmin(post_uuid: $post_uuid){
        response
        responseStatus
      }
    }
  }
`;
