import gql from 'graphql-tag';

export const LIST_ARTICLES_ADMIN = gql`
  query listArticlesAdmin($filters: mixedFilters) {
    adminRoute {
      listArticlesAdmin(filters:$filters) {
        count
        rows {
          id
          uuid
          slug
          cover
          preview
          title
          content
          active
          tags
          fixed
        }
      }
    }
  }
`;

export const GET_ARTICLE_ADMIN = gql`
  query getArticleAdmin($article_uuid: String!) {
    adminRoute {
      getArticleAdmin(article_uuid: $article_uuid) {
        id
        uuid
        cover
        preview
        title
        slug
        content
        mobile_content
        active
        tags
        fixed
        metadata {
          name
          content
        }
      }
    }
  }
`;

export const CREATE_ARTICLE_ADMIN = gql`
  query createArticleAdmin($article_data: ArticleInput!) {
    adminRoute {
      createArticleAdmin(article_data: $article_data) {
        response
        responseStatus
      }
    }
  }
`;

export const UPDATE_ARTICLE_ADMIN = gql`
  query updateArticleAdmin($article_uuid: String!, $article_data: ArticleInput!) {
    adminRoute {
      updateArticleAdmin(article_uuid: $article_uuid, article_data: $article_data) {
        response
        responseStatus
      }
    }
  }
`;

export const DELETE_ARTICLE_ADMIN = gql`
  query deleteArticleAdmin($article_uuid: String!) {
    adminRoute {
      deleteArticleAdmin(article_uuid: $article_uuid) {
        response
        responseStatus
      }
    }
  }
`;

export const UPLOAD_ARTICLE_COVER = gql`
  query uploadArticleCover($article_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadArticleCover(article_uuid: $article_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;

export const UPLOAD_ARTICLE_PREVIEW = gql`
  query uploadArticlePreview($article_uuid: String!, $filename: String!, $contentType: String!) {
    adminRoute {
      uploadArticlePreview(article_uuid: $article_uuid, filename: $filename, contentType: $contentType) {
        presignedUrl
        key
      }
    }
  }
`;
