import React, { useCallback, useEffect, useRef, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { Box, Heading } from '@chakra-ui/react';
import './custom-calendar.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';
import { Checkbox, Divider, FormControlLabel, useMediaQuery } from '@mui/material';
import { useCalendar } from '../../../hooks/Calendar/useCalendar';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckBox from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useAuthentication } from '../../../utils/useAuthentication';
import { useOffices } from '../../../hooks/Offices/useOffices';
import { useTheme } from '@mui/material/styles';
import ModalAppointmentDetails from '../modals/modalAppointmentDetails';
import itLocale from '@fullcalendar/core/locales/it';
import { useAccounts } from '../../../hooks/Accounts/useAccounts';
import { IconAccessibleOff, IconCalendarOff, IconChecks, IconCircleRectangle, IconPlus, IconPrinter } from '@tabler/icons-react';
import ModalAppointmentEdit from '../modals/modalAppointmentEdit';
import ModalBlock from './modalBlock';
import { toast } from 'react-toastify';
import colors from './colors';
import { ModalCalendarPrint } from './modalCalendarPrint';
import { UiDialog } from '../../utilities/Dialog';
import { UILoader } from '../../utilities/Loader';

const CalendarView = () => {
  let calendar = useRef();
  const data = useAuthentication();
  const user = data?.user?.account_data;
  const [loading, setLoading] = useState(false);
  const [FcDateStart, setFcDateStart] = useState(moment().startOf('week').add(1, 'day').toDate());
  const [FcDateEnd, setFcDateEnd] = useState(moment().endOf('week').add(1, 'day').toDate());
  const [MiniDate, setMiniDate] = useState(new Date());
  const [doctors, setDoctors] = useState([]);
  const [selectedDoctors, setSelectedDoctors] = useState([user?.id]);
  const [showDoctors, setShowDoctors] = useState(false);
  const [offices, setOffices] = useState([]);
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [showOffices, setShowOffices] = useState(false);
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [blockMode, setBlockMode] = useState(false);
  const [printMode, setPrintMode] = useState(false);
  const [deleteBlockMode, setDeleteBlockMode] = useState(false);
  const [blockId, setBlockId] = useState(null);
  const [deleteWaitingListMode, setDeleteWaitingListMode] = useState(false);
  const [waitingListId, setWaitingListId] = useState(false);

  /** RESPONSIVE */
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const { onGetDoctorsAppointments, onUpdateAppointmentTime, onGetAppointment, onDeleteBlock, onDeleteWaitingListBlock } = useCalendar();
  const { onListOffices } = useOffices();
  const { onListDoctors } = useAccounts();

  useEffect(() => {
    if (user?.admin || user?.clinic) {
      onListDoctors().then((res) => {
        setDoctors(res);
      });
    }
    onListOffices().then((res) => {
      setOffices(res);
    });
  }, []);

  //*** QUERIES */
  const getAppointmentData = async (appointment_id) => {
    let res = await onGetAppointment({ appointment_id });
    setAppointment(res);
  };
  const getDoctorsAppointments = useCallback(async () => {
    onGetDoctorsAppointments({
      doctors: selectedDoctors,
      offices: selectedOffices,
      start: FcDateStart,
      end: FcDateEnd
    }).then((res) => {
      let events = res?.appointments?.map((item) => {
        let colorIndex;

        if (user?.admin || user?.clinic) {
          colorIndex = doctors?.findIndex((doctor) => doctor.id === item.calendar.doctor_id);
        } else {
          colorIndex = offices?.findIndex((office) => office.id === item.calendar.office_id);
        }

        if (colorIndex > colors.length) {
          colorIndex = colorIndex - colors.length;
        }

        if (['blocked'].includes(item.status)) {
          let title = 'Bloccato';
          if (item?.holiday_name) {
            title += ` (${item?.holiday_name})`;
          }

          return {
            title: title,
            start: moment(item.date + ' ' + item?.start_hour).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment(item.date + ' ' + item?.end_hour).format('YYYY-MM-DDTHH:mm:ss'),
            textColor: 'black',
            backgroundColor: 'lightgrey',
            borderColor: 'black',
            extendedProps: {
              appointment_id: item.id,
              patient: item.patient,
              treatment: item.treatment,
              status: item.status
            }
          };
        }

        if (['automatic'].includes(item.status)) {
          let title = "Lista d'attesa";

          return {
            title: title,
            start: moment(item.date + ' ' + item?.start_hour).format('YYYY-MM-DDTHH:mm:ss'),
            end: moment(item.date + ' ' + item?.end_hour).format('YYYY-MM-DDTHH:mm:ss'),
            textColor: 'black',
            backgroundColor: 'lightgrey',
            borderColor: 'black',
            extendedProps: {
              appointment_id: item.id,
              patient: item.patient,
              treatment: item.treatment,
              status: item.status
            }
          };
        }

        let bg_color;
        let border_color;
        switch (item.status) {
          default:
            bg_color = colors[colorIndex]?.background;
            border_color = colors[colorIndex]?.border;
            break;
          case 'canceled':
          case 'rejected':
            bg_color = '#e4aeae';
            border_color = '#bd6363';
            break;
        }

        return {
          title: item?.patient ? item.patient?.name + ' ' + item?.patient?.surname : item.patient_alias,
          start: moment(item.date + ' ' + item?.start_hour).format('YYYY-MM-DDTHH:mm:ss'),
          end: moment(item.date + ' ' + item?.end_hour).format('YYYY-MM-DDTHH:mm:ss'),
          textColor: colors[colorIndex]?.color,
          backgroundColor: bg_color,
          borderColor: border_color,
          extendedProps: {
            appointment_id: item.id,
            patient: item.patient,
            treatment: item.treatment,
            status: item.status,
            confirmed: item.status === 'confirmed',
            canceled: item.status === 'canceled' || item.status === 'rejected',
            not_shown: item.patient_not_shown
          }
        };
      });

      let hours = res?.hours?.map((item) => {
        let colorIndex;

        colorIndex = offices?.findIndex((office) => office.id === item.office_id);

        if (colorIndex > colors.length) {
          colorIndex = colorIndex - colors.length;
        }
        return {
          ...item,
          backgroundColor: colors[colorIndex]?.background,
          borderColor: 'transparent',
          display: 'background'
        };
      });

      setEvents(events?.concat(hours));
    });
  }, [selectedDoctors, selectedOffices, FcDateStart, FcDateEnd, doctors]);

  const handleModalDetailsClose = () => {
    setModalOpen(false);
    setAppointment(null);
    getDoctorsAppointments().then(() => {});
  };
  const handleModalAppointmentClose = () => {
    setEditMode(false);
    if (appointment) {
      getAppointmentData(appointment.id).then(() => {});
    }
    getDoctorsAppointments().then(() => {});
  };
  const handleModalBlockClose = () => {
    setBlockMode(false);
    getDoctorsAppointments().then(() => {});
  };
  const handleModalPrintClose = () => {
    setPrintMode(false);
    getDoctorsAppointments().then(() => {});
  };

  const handleDeleteBlock = async () => {
    onDeleteBlock({
      block_id: blockId
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      setDeleteBlockMode(null);
      setBlockId(null);
      getDoctorsAppointments().then(() => {});
    });
  };

  const handleDeleteWaitingList = async () => {
    onDeleteWaitingListBlock({
      appointment_id: waitingListId
    }).then((res) => {
      toast(res?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: '#00e676',
          color: '#ffffff'
        }
      });
      setDeleteWaitingListMode(null);
      setWaitingListId(null);
      getDoctorsAppointments().then(() => {});
    });
  };

  /*** TEXT FORMATTER ***/
  const titleFormat = (locale) => {
    let start = moment(locale.start);
    let end = moment(locale.end);

    if (matchDownSM) {
      return start.format('DD') + ' - ' + end.format('DD') + ' ' + start.format('MMMM');
    } else {
      let string = start.format('DD');

      if (start.format('DD') !== moment(locale.end).format('DD')) {
        if (start.format('MM') !== end.format('MM')) {
          if (start.format('YYYY') !== end.format('YYYY')) {
            string += ` ${end.format('YYYY')}`;
          } else {
            string += ` ${start.format('MMMM')} - ${end.format('DD MMMM, YYYY')}`;
          }
        } else {
          string += ` - ${end.format('DD MMMM, YYYY')}`;
        }
      }

      return string;
    }
  };
  const weekFormat = (locale, date) => {
    let options = { weekday: 'short' };
    return new Date(date).toLocaleString('it-IT', options);
  };

  /*** CALENDAR HANDLERS ***/
  const CalendarHandleDateClick = (arg) => {
    calendar.current.getApi().gotoDate(arg);
    setFcDateStart(arg);
  };

  /*** FULLCALENDAR HANDLERS ***/
  const FcHandleEventMount = ({ event, el }) => {
    el.style.borderRadius = '4px';
    el.style.borderLeft = '4px solid ' + event.borderColor;
  };
  const FCHandleDateChange = (arg) => {
    let startDate = moment(arg.startStr).toDate();
    let endDate = moment(arg.endStr).toDate();
    setMiniDate(startDate);
    setFcDateStart(startDate);
    setFcDateEnd(endDate);
  };
  const FCHandleEventClick = (e) => {
    let appointment_id = e.event.extendedProps.appointment_id;
    let status = e.event.extendedProps.status;

    if (['automatic'].includes(status)) {
      setDeleteWaitingListMode(true);
      setWaitingListId(appointment_id);
      return;
    }

    if (!['blocked'].includes(status)) {
      getAppointmentData(appointment_id).then(() => {
        setModalOpen(true);
      });
    } else {
      setDeleteBlockMode(true);
      setBlockId(appointment_id);
    }
  };
  const FcHandleEventUpdate = (e) => {
    setLoading(true);

    let status = e.event.extendedProps.status;

    if (['blocked', 'automatic'].includes(status)) {
      let message =
        (status === 'blocked' && 'Non puoi spostare un blocco orario') ||
        (status === 'automatic' && 'Non puoi spostare un blocco di lista automatica');

      toast(message, {
        style: {
          fontSize: '14px',
          backgroundColor: 'red',
          color: '#ffffff'
        }
      });
      e.revert();
      setLoading(false);
      return;
    }

    let appointment_data = {
      start_date: moment(e.event.startStr).utc(true),
      end_date: moment(e.event.endStr).utc(true)
    };
    onUpdateAppointmentTime({
      appointment_id: e.event.extendedProps.appointment_id,
      appointment_data
    }).then((response) => {
      toast(response?.response, {
        style: {
          fontSize: '14px',
          backgroundColor: response?.responseStatus ? '#00e676' : 'red',
          color: '#ffffff'
        }
      });

      if (response?.responseStatus) {
        getDoctorsAppointments().then(() => {
          setLoading(false);
        });
      } else {
        e.revert();
        setLoading(false);
      }
    });
  };
  const FcHandleEventContent = (e) => {
    return (
      <>
        <Box className={'fc-content'} position={'relative'}>
          <Box className={'fc-title'}>{e.timeText}</Box>
          <Box className={'fc-title'} fontWeight={'bold'}>
            {e.event?.title}
          </Box>
          <Box className={'fc-title'}>{e.event?.extendedProps?.treatment?.name}</Box>
          {e.event?.extendedProps?.confirmed && (
            <Box pos={'absolute'} top={0} right={0} color={'green'}>
              <IconChecks stroke={2} size="1rem" />
            </Box>
          )}
          {e.event?.extendedProps?.canceled && (
            <Box pos={'absolute'} top={0} right={0} color={'#e82424'}>
              <IconCircleRectangle stroke={2} size="1rem" />
            </Box>
          )}
          {e.event?.extendedProps?.not_shown && (
            <Box pos={'absolute'} top={0} right={0} color={'#e82424'}>
              <IconAccessibleOff stroke={2} size="1rem" />
            </Box>
          )}
        </Box>
      </>
    );
  };

  /*** CONTROLS HANDLERS ***/
  const ControlsHandleSelectAllDoctors = () => {
    if (selectedDoctors.length === doctors.length) {
      setSelectedDoctors([]);
    } else {
      setSelectedDoctors(doctors?.map((doctor) => doctor.id));
    }
  };
  const ControlsHandleSelectAllOffices = () => {
    if (selectedOffices.length === offices.length) {
      setSelectedOffices([]);
    } else {
      setSelectedOffices(offices?.map((office) => office.id));
    }
  };

  useEffect(() => {
    getDoctorsAppointments().then(() => {});
  }, [getDoctorsAppointments]);

  return (
    <Box display={matchDownSM ? 'block' : 'flex'} className={'container'}>
      {loading && <UILoader loading={loading} />}

      <UiDialog
        type={'default'}
        variant={'alert'}
        title={'Vuoi eliminare il blocco orario?'}
        open={deleteBlockMode}
        onClose={() => setDeleteBlockMode(false)}
        onConfirm={handleDeleteBlock}
      />

      <UiDialog
        type={'default'}
        variant={'alert'}
        title={"Vuoi eliminare il blocco di lista d'attesa automatica?"}
        open={deleteWaitingListMode}
        onClose={() => setDeleteWaitingListMode(false)}
        onConfirm={handleDeleteWaitingList}
      />

      <Box
        position={'fixed'}
        display={'flex'}
        right={10}
        bottom={10}
        bg={'#2c3e50'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        zIndex={999}
        onClick={() => {
          setAppointment(null);
          setEditMode(true);
        }}
      >
        <IconPlus stroke={3} size="2rem" />
      </Box>
      <Box
        position={'fixed'}
        display={'flex'}
        bottom={10}
        bg={'#2c3e50'}
        borderRadius={'100%'}
        w={'50px'}
        h={'50px'}
        color={'white'}
        justifyContent={'center'}
        alignItems={'center'}
        cursor={'pointer'}
        zIndex={999}
        onClick={() => {
          setBlockMode(true);
        }}
      >
        <IconCalendarOff stroke={1.5} size="2rem" />
      </Box>
      {!user?.admin && !user?.clinic && (
        <Box
          position={'fixed'}
          display={'flex'}
          bottom={70}
          bg={'#2c3e50'}
          borderRadius={'100%'}
          w={'50px'}
          h={'50px'}
          color={'white'}
          justifyContent={'center'}
          alignItems={'center'}
          cursor={'pointer'}
          zIndex={999}
          onClick={() => {
            setPrintMode(true);
          }}
        >
          <IconPrinter stroke={1.5} size="2rem" />
        </Box>
      )}
      <Box>
        <ModalAppointmentDetails appointment={appointment} handleModalClose={handleModalDetailsClose} modalOpen={modalOpen} />
        <ModalCalendarPrint handleModalClose={handleModalPrintClose} modalOpen={printMode} />
        <ModalAppointmentEdit
          modalOpen={editMode}
          handleModalClose={handleModalAppointmentClose}
          setModalOpen={setEditMode}
          handleModalDetailsClose={handleModalDetailsClose}
          appointment={appointment}
          user={user}
        />
        <ModalBlock modalOpen={blockMode} handleModalClose={handleModalBlockClose} />
      </Box>
      <Box className="control-console" h={'100%'} mr={'10px'} m={'20px auto'}>
        {!matchDownSM && (
          <>
            <Box className={'mini-calendar'}>
              <Calendar
                onClickDay={CalendarHandleDateClick}
                value={MiniDate}
                defaultView="month"
                className="custom-calendar"
                maxDetail={'month'}
                minDetail={'year'}
                showNeighboringMonth={true}
                formatShortWeekday={weekFormat}
                prev2Label={null}
                next2Label={null}
              />
            </Box>
            <Divider />
          </>
        )}

        {(user?.admin || user?.clinic) && (
          <Box className={'specialists'}>
            <Heading
              as="h2"
              display={'flex'}
              alignItems={'center'}
              justifyContent={'start'}
              cursor={'pointer'}
              color={'#1b2734'}
              fontSize={'1rem'}
              fontWeight={400}
            >
              <Box onClick={() => setShowDoctors(!showDoctors)}>
                {!showDoctors ? <KeyboardArrowRightIcon size={14} /> : <KeyboardArrowDownIcon size={14} />}
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon />}
                      checked={selectedDoctors?.length > 0}
                      checkedIcon={selectedDoctors?.length === doctors?.length ? <CheckBox /> : <IndeterminateCheckBoxIcon />}
                      onChange={() => ControlsHandleSelectAllDoctors()}
                      color={'primary'}
                    />
                  }
                  label="Specialisti"
                  style={{
                    marginLeft: 1,
                    height: 20
                  }}
                />
              </Box>
            </Heading>
            <Box ml={40} mt={4} display={showDoctors ? 'block' : 'none'}>
              {doctors &&
                doctors?.map((doctor, index) => (
                  <Box key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            if (selectedDoctors.includes(doctor?.id)) {
                              setSelectedDoctors((prev) => prev.filter((item) => item !== doctor?.id));
                            } else {
                              setSelectedDoctors((prev) => [...prev, doctor?.id]);
                            }
                          }}
                          checked={selectedDoctors.includes(doctor?.id)}
                          style={{
                            color: colors[doctors?.findIndex((item) => item.id === doctor?.id)]?.background
                          }}
                        />
                      }
                      label={doctor?.name}
                      style={{
                        marginTop: 10,
                        height: 20
                      }}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        )}

        <Box className={'offices'}>
          <Heading
            as="h2"
            display={'flex'}
            alignItems={'center'}
            justifyContent={'start'}
            cursor={'pointer'}
            color={'#1b2734'}
            fontSize={'1rem'}
            fontWeight={400}
          >
            <Box onClick={() => setShowOffices(!showOffices)}>
              {!showOffices ? <KeyboardArrowRightIcon size={14} /> : <KeyboardArrowDownIcon size={14} />}
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon />}
                    checked={selectedOffices?.length > 0}
                    checkedIcon={selectedOffices?.length === offices?.length ? <CheckBox /> : <IndeterminateCheckBoxIcon />}
                    onChange={() => ControlsHandleSelectAllOffices()}
                    color={'primary'}
                  />
                }
                label="Uffici"
                style={{
                  marginLeft: 1,
                  height: 20
                }}
              />
            </Box>
          </Heading>
          <Box ml={40} mt={4} display={showOffices ? 'block' : 'none'}>
            {offices &&
              offices?.map((office, index) => (
                <Box key={index}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => {
                          if (selectedOffices.includes(office?.id)) {
                            setSelectedOffices((prev) => prev.filter((item) => item !== office?.id));
                          } else {
                            setSelectedOffices((prev) => [...prev, office?.id]);
                          }
                        }}
                        checked={selectedOffices.includes(office?.id)}
                        style={{
                          color: colors[offices?.findIndex((item) => item.id === office?.id)]?.color
                        }}
                      />
                    }
                    label={office?.name}
                    style={{
                      marginTop: 10,
                      height: 20
                    }}
                  />
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
      <Box height={'100vh'} overflow="auto" className={'calendar'}>
        <FullCalendar
          ref={calendar}
          initialDate={FcDateStart}
          initialView={matchDownSM ? 'listWeek' : 'timeGridWeek'}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
          locale={itLocale}
          headerToolbar={
            !matchDownSM
              ? {
                  left: 'prev,next',
                  center: 'title',
                  right: 'timeGridWeek,timeGridDay,listWeek,listMonth'
                }
              : {
                  left: 'prev,next',
                  center: 'title',
                  right: 'listWeek,listMonth'
                }
          }
          views={{
            timeGridWeek: {
              buttonText: 'Settimana'
            },
            timeGridDay: {
              buttonText: 'Giorno'
            },
            listWeek: {
              buttonText: matchDownSM ? 'Settimana' : 'Elenco Settimana'
            },
            listMonth: {
              buttonText: matchDownSM ? 'Mese' : 'Elenco Mese'
            }
          }}
          firstDay={1}
          expandRows={true}
          editable={!loading}
          slotEventOverlap={false}
          eventShortHeight={true}
          contentHeight={'auto'}
          dayMaxEvents={true}
          eventMaxStack={3}
          slotDuration={'00:05:00'}
          slotLabelInterval={'00:30:00'}
          slotMinTime={'06:30:00'}
          slotMaxTime={'23:30:00'}
          eventDidMount={FcHandleEventMount}
          eventContent={FcHandleEventContent}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: 'short',
            hour12: false
          }}
          selectable={true}
          titleFormat={titleFormat}
          datesSet={(args) => FCHandleDateChange(args)}
          eventClick={FCHandleEventClick}
          eventResize={FcHandleEventUpdate}
          eventDrop={FcHandleEventUpdate}
          events={events}
        />
      </Box>
    </Box>
  );
};
export default CalendarView;
