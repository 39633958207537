import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './custom-doctors.css';
import { IconArrowsSort, IconPhoto } from '@tabler/icons-react';
import UITable from '../../utilities/Table';
import { SectionHeader, UIPageHeader } from '../../utilities/Headers';
import { UIButton } from '../../utilities/Button';
import { useSocial } from '../../../hooks/Social/useSocial';
import ModalViewPosts from './modalViewPosts';

const UsersView = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [picsMode, setPicsMode] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [filtersData, setFiltersData] = useState({
    order: null
  });

  const { onListSocialUsersAdminPaginated } = useSocial();

  useEffect(() => {

  }, []);

  const handleModalClose = useCallback(() => {
    setPicsMode(false);
    setSelectedUser(null);
    setForceUpdate(true);
  }, []);

  const prepareValues = (users) =>
    users?.map((user) => {
      return {
        user,
        userEl: (
          <Box>
            <Typography fontFamily={'Oxygen'} fontWeight={'700'} fontSize={'14px'}>
              {user?.name} {user?.surname}
            </Typography>
            <Typography fontFamily={'Oxygen'} fontWeight={'400'} fontSize={'13px'}>
              {user?.social_nickname}
            </Typography>
          </Box>
        ),
        actionsEl: (
          <Box width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} mr={'15px'}>
            <UIButton
              type={'icon'}
              onClick={() => {
                setSelectedUser(user);
                setPicsMode(true);
              }}
              icon={
                <IconPhoto
                  stroke={'1.67px'}
                  style={{
                    width: '24px'
                  }}
                />
              }
            />
          </Box>
        )
      };
    });

  const filtersButtons = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        gap: '10px'
      }}
    >
      <Box width={'120px'}>
        <UIButton
          icon={
            <IconArrowsSort
              stroke={'1.64'}
              size={'20px'}
              style={{
                marginRight: 5,
                width: '20px'
              }}
            />
          }
          checked={filtersData?.order !== null}
          label={'ABC'}
          type={'filter'}
          onClick={() => {
            setFiltersData({
              ...filtersData,
              order: filtersData?.order === 'ASC' ? null : 'ASC'
            });
          }}
        />
      </Box>
    </Box>
  );

  return (
    <Box className="products-view" w={'100%'} h={'100%'} overflow={'auto'}>
      <ModalViewPosts modalOpen={picsMode} handleModalClose={handleModalClose} user={selectedUser}/>

      <UIPageHeader
        title={'Post Social'}
        subtitle={
          `Gestisci qui i post degli utenti del social network. Gli utenti che non hanno completato l'onboarding non saranno mostrati.`
        }
      />

      <SectionHeader title={'Autore'} />
      <UITable
        columns={[
          {
            name: 'Utente',
            value: 'userEl',
            size: 120
          },
          {
            name: '',
            value: 'actionsEl',
            size: 80
          }
        ]}
        prepareValues={prepareValues}
        enableSearch={true}
        searchSubtitle={`E' possibile cercare per nome, cognome, email, numero di cellulare o nickname`}
        enableCustomFilters={true}
        filtersData={filtersData}
        filtersButtons={filtersButtons}
        tableHeight={'calc(100vh - 400px)'}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
        infiniteScrollEndMessage={'Non ci sono altri utenti da mostrare'}
        infiniteScrollFunction={onListSocialUsersAdminPaginated}
      />
    </Box>
  );
};
export default UsersView;
