import {  Button } from '@mui/material';
import React from 'react';
import { IconAdjustmentsHorizontal, IconPlus } from '@tabler/icons-react';
import { Box } from '@chakra-ui/react';

export const UIButton = ({
  variant = 'confirm',
  type = 'button',
  label,
  onClick = () => {},
  icon = null,
  checked = false,
  disabled = false,
  formButtonType = null,
  sxCustom = {},
  ...rest
}) => {
  let sx;

  switch (variant) {
    case 'confirm':
      sx = {
        backgroundColor: '#B5FDC1',
        color: '#344054',
        fontFamily: 'Oxygen',
        fontWeight: '700',
        fontSize: '13px',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#CDCDCD',
          color: '#344054'
        }
      };
      break;
    case 'cancel':
      sx = {
        backgroundColor: '#FF646C',
        color: '#FFFFFF',
        fontFamily: 'Oxygen',
        fontWeight: '700',
        fontSize: '13px',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#CDCDCD',
          color: '#344054'
        }
      };
      break;
    case 'alert':
      sx = {
        backgroundColor: '#FE922E',
        color: '#FFFFFF',
        fontFamily: 'Oxygen',
        fontWeight: '700',
        fontSize: '13px',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#CDCDCD',
          color: '#344054'
        }
      };
      break;
    case 'phone':
      sx = {
        backgroundColor: '#B5FDC1',
        color: '#000',
        fontFamily: 'Oxygen',
        fontWeight: '700',
        fontSize: '13px',
        borderRadius: '8px',
        border: '1px solid #D0D5DD',
        '&:hover': {
          backgroundColor: '#CDCDCD',
          color: '#344054'
        }
      };
      break;
    case 'empty':
      sx = {
        backgroundColor: '#EFEFEF',
        color: '#000',
        fontFamily: 'Oxygen',
        fontWeight: '700',
        fontSize: '13px',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#CDCDCD',
          color: '#344054'
        }
      };
      break;
    case 'copy':
      sx = {
        backgroundColor: '#FDF2B5',
        color: '#344054',
        fontFamily: 'Oxygen',
        fontWeight: '700',
        fontSize: '13px',
        borderRadius: '8px',
        border: '1px solid #D0D5DD',
        '&:hover': {
          backgroundColor: '#CDCDCD',
          color: '#344054'
        }
      };
      break;
  }

  switch (type) {
    case 'button':
      sx = {
        ...sx
      };
      break;

    case 'icon':
      sx = {
        ...sx,
        backgroundColor: 'transparent',
        minWidth: 'auto',
        color: '#667085'
      };
      break;
    case 'filter':
      sx = {
        ...sx,
        backgroundColor: checked ? '#FDF2B5' : '#FFFFFF',
        color: '#344054',
        border: '1px solid #D0D5DD',
        fontSize: '14px',
        p: '10px 5px',
        width: '100%',
        borderRadius: '8px'
      };
      break;
    case 'submit':
      sx = {
        backgroundColor: '#00E676',
        color: '#FFF',
        borderRadius: '100%',
        width: '50px',
        height: '50px',
        minWidth: 'auto',
        minHeight: 'auto'
      };
      break;
    case 'modal-option':
      sx = {
        backgroundColor: '#FDF2B5',
        color: '#344054',
        borderRadius: '100%',
        width: '50px',
        height: '50px',
        minWidth: 'auto',
        minHeight: 'auto',
        boxShadow: 4
      };
      break;
      case 'editor-menu':
      sx = {
        color:'black',
        py: 1,
        px: 1,
        m: '2px',
        minWidth: '20px',
      };
      break;
  }

  return (
    <>
      {type === 'button' && (
        <Button
          variant="contained"
          sx={{
            pointerEvents: disabled ? 'none' : 'all',
            ...sx,
            ...sxCustom
          }}
          onClick={onClick}
          disabled={disabled}
          type={formButtonType ?? 'button'}
          {...rest}
          disableElevation
        >
          {label || icon}
        </Button>
      )}
      {(type === 'icon' || type === 'submit' || type === 'modal-option') && (
        <Button
          variant={'text'}
          sx={{
            pointerEvents: disabled ? 'none' : 'all',
            ...sx,
            ...sxCustom
          }}
          onClick={onClick}
          disabled={disabled}
          {...rest}
          disableElevation
        >
          {icon}
        </Button>
      )}
      {(type === 'editor-menu') && (
        <Button
          variant={'text'}
          sx={{
            pointerEvents: disabled ? 'none' : 'all',
            ...sx,
            ...sxCustom
          }}
          onClick={onClick}
          disabled={disabled}
          {...rest}
          disableElevation
        >
          {label} {icon}
        </Button>
      )}
      {type === 'filter' && (
        <Button
          display={'flex'}
          sx={{
            pointerEvents: disabled ? 'none' : 'all',
            ...sx,
            ...sxCustom
          }}
          onClick={onClick}
          disabled={disabled}
          {...rest}
          disableElevation
        >
          {icon ? (
            icon
          ) : (
            <IconAdjustmentsHorizontal
              stroke={'1.2px'}
              style={{
                marginRight: 5,
                width: '20px'
              }}
            />
          )}
          {label}
        </Button>
      )}
    </>
  );
};


export const UIButtonFloating = ({
                                   right = 10,
                                   bottom = 10,
                                   onClick = () => {
                                   }
                                 }) => {
  return (
    <Box
      position={'fixed'}
      display={'flex'}
      right={right}
      bottom={bottom}
      bgColor={'#2c3e50'}
      p={'10px'}
      borderRadius={'100%'}
      w={'50px'}
      h={'50px'}
      color={'white'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={onClick}
      cursor={'pointer'}
    >
      <IconPlus stroke={3} size="2rem" />
    </Box>
  );
};
