// assets
import { IconArticle } from '@tabler/icons-react';
// constant
const icons = { IconArticle };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const accounts = {
  id: 'articles',
  type: 'group',
  permission: ['ADMIN'],
  children: [
    {
      id: 'articles_menu',
      title: 'Blog',
      type: 'collapse',
      icon: icons.IconArticle,
      breadcrumbs: false,
      permission: ['ADMIN'],
      children: [
        {
          id: 'articles_list',
          title: 'Articoli',
          type: 'item',
          url: '/articles',
          permission: ['ADMIN'],
          icon: icons.IconArticle,
          breadcrumbs: false
        },
        {
          id: 'glossary_list',
          title: 'Glossario',
          type: 'item',
          url: '/glossary',
          permission: ['ADMIN'],
          icon: icons.IconArticle,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default accounts;
